<template>
  <button class="btn" @click="download">
    <slot>
      <fa-icon icon="fa-download" />
      <span>Tout télécharger</span>
    </slot>
    <span v-if="this.show_size && this.size" class="fw-light size-hint"
      >{{ this.size }}Go</span
    >
  </button>
</template>

<script>
import { Storage } from "aws-amplify";

export default {
  props: {
    path: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      default: "public",
    },
    show_size: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      size: 0,
    };
  },
  mounted() {
    let sz = 0;
    Storage.list(this.path, { pageSize: "ALL" })
      .then((response) => {
        response.results.forEach((r) => {
          sz += ~~(r.size / Math.pow(2, 10));
        });
      })
      .finally(() => {
        this.size = (sz / Math.pow(2, 20)).toFixed(2);
      });
  },
  methods: {
    download() {
      Storage.list(this.path, { pageSize: "ALL" }).then(async (response) => {
        for (let i = 0; i < response.results.length; i++) {
          const obj = response.results[i];
          if (obj.size > 1000) {
            const url = await Storage.get(obj.key, {
              download: false,
              expires: 900,
            });
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `archive-${i}.zip`;

            // Append to the DOM
            document.body.appendChild(anchor);

            // Trigger `click` event
            anchor.click();

            // Remove element from DOM
            document.body.removeChild(anchor);

            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      });
    },
  },
};
</script>

<style>
.size-hint {
  margin-left: 0.5rem;
}
</style>
