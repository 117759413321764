<template>
  <div class="container-fluid position-relative my-1 photo-div">
    <div
      class="download-all d-inline-flex justify-content-center align-items-center"
    >
      <download-button
        path="static/zips/skler/Ana et Thibaut 2023.zip"
        class="btn btn-primary"
        :show_size="true"
      />
    </div>
    <div class="thanks-skler">
      <a href="https://www.instagram.com/julienskler/">
        <img src="@/assets/images/skler.jpg" />
      </a>
      <span class="fst-light text-muted">
        Merci encore à mon ami
        <a href="https://www.instagram.com/julienskler/" class="text-reset"
          >@Julien SKLER</a
        >
        pour ces magnifiques photos
      </span>
    </div>
    <s-3-mansonry-paginated loadPrefix="skler/" />
  </div>
</template>

<script>
import DownloadButton from "@/components/Forms/DownloadButton.vue";
import S3MansonryPaginated from "@/components/Images/S3MansonryPaginated.vue";

export default {
  components: {
    DownloadButton,
    S3MansonryPaginated,
  },
  computed: {
    page() {
      return parseInt(this.$route.params.page);
    },
  },
  data() {
    return {
      files: [],
    };
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.thanks-skler {
  img {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    margin: 1rem;
  }
}

.btn > a {
  all: unset;
}

.size-hint {
  margin: auto;
  margin-left: 0.5rem;
}
</style>
