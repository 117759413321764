<script>
import { token } from "@formkit/utils";
import { API } from "aws-amplify";
import { createGuest, updateGuest, deleteGuest } from "@/graphql/mutations.ts";
import { listGuests } from "@/graphql/queries.ts";
import { v4 as uuidv4 } from "uuid";
import loadValue from "@/assets/js/utils";
/*import { mapGetters } from "vuex";*/

export default {
  data() {
    return {
      values: [],
      initialValues: {},
    };
  },
  setup() {
    return { loadValue };
  },
  created() {
    this.$store.dispatch("startLoading");
    API.graphql({
      query: listGuests,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then((r) => {
        for (const i in r["data"]["listGuests"]["items"]) {
          const guest = r["data"]["listGuests"]["items"][i];
          this.initialValues[guest["id"]] = guest;
        }
      })
      .finally(() => {
        if (Object.keys(this.initialValues).length === 0)
          this.initialValues[uuidv4()] = null;
        console.log(this.initialValues);
        this.$store.dispatch("stopLoading");
      });
  },
  methods: {
    addItem() {
      console.log(token());
      this.initialValues[uuidv4()] = null;
    },
    removeItem(e) {
      e.preventDefault();
      const key = e.target.getAttribute("data-key");
      console.log(`Remove ${key}`);
      delete this.initialValues[key];
    },
    async submitForm() {
      console.log("Submission...");
      this.$store.dispatch("startLoading");
      try {
        const existing_guests = await API.graphql({
          query: listGuests,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const existingGuestsIds = existing_guests["data"]["listGuests"][
          "items"
        ].map((g) => g["id"]);
        const formGuests = this.values["guests"];
        const formGuestsIds = this.values["guests"].map((g) => g["id"]);
        console.debug(formGuests);

        for (const i in formGuests) {
          const item = formGuests[i];
          console.log(item);
          try {
            // Update if exists
            if (existingGuestsIds.includes(item["id"])) {
              await API.graphql({
                query: updateGuest,
                variables: {
                  input: item,
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              console.debug(
                `Updated ${item["id"]}: ${item["first_name"]} ${item[
                  "last_name"
                ].toUpperCase()}`
              );
              //Create if not exists
            } else {
              await API.graphql({
                query: createGuest,
                variables: {
                  input: item,
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              console.debug(
                `Created ${item["id"]}: ${item["first_name"]} ${item[
                  "last_name"
                ].toUpperCase()}`
              );
            }
          } catch (exception) {
            console.error(exception);
          }
          /*this.$router.push({ name: "home" });*/
        }
        for (const i in existingGuestsIds) {
          try {
            const itemId = existingGuestsIds[i];
            if (!formGuestsIds.includes(itemId)) {
              await API.graphql({
                query: deleteGuest,
                variables: {
                  input: {
                    id: itemId,
                  },
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              console.debug(`Delete ${itemId}`);
            }
          } catch (e) {
            console.error(e);
          }
        }
        var guestsNames = formGuests.map((e) => {
          return `${e['first_name']} ${e['last_name']}`
        })
        this.$store.dispatch("pushMessage", {
          title: "Votre confirmation a été reçue!",
          src: require("@/assets/images/icons/check.gif"),
          lines: guestsNames,
          color: '#0dcaf0',
          delay: 10000
        });
      } finally {
        this.$store.dispatch("stopLoading");
      }
    },
  },
};
</script>

<template>
  <FormKit type="form" v-model="values" :actions="false" @submit="submitForm">
    <FormKit type="list" name="guests">
      <h2>Renseignez ici votre nom, prénom et régime alimentaire</h2>
      <FormKit
        type="group"
        wrapper
        v-for="(val, key) in this.initialValues"
        :key="key"
        :id="key"
      >
        <div class="relative">
          <div class="row py-2">
            <FormKit name="id" type="hidden" :value="key" />
            <FormKit
              outer-class="col-sm-12 col-md-6 col-lg-3"
              name="last_name"
              :value="loadValue(val, 'last_name')"
              type="text"
              label="Nom"
              validation="required"
            />
            <FormKit
              outer-class="col-sm-12 col-md-6 col-lg-3"
              name="first_name"
              :value="loadValue(val, 'first_name')"
              type="text"
              label="Prénom"
              validation="required"
            />
            <FormKit
              outer-class="col-sm-12 col-md-6 col-lg-3"
              input-class="form-select"
              name="food"
              :value="loadValue(val, 'food')"
              type="select"
              label="Régime"
              validation="required"
            >
              <option value="classic">🍖 Classic</option>
              <option value="kasher">🐟 Kasher</option>
              <option value="vegetarian">🥦 Vegetarian</option>
            </FormKit>
            <FormKit
              outer-class="col-sm-12 col-md-6 col-lg-3"
              name="allergy"
              type="text"
              label="Allergies"
              validation=""
              :value="loadValue(val, 'allergy')"
            />
          </div>
          <button
            type="button"
            class="btn btn-outline-danger absolute tr-0"
            :data-key="key"
            @click="removeItem"
          >
            <fa-icon icon="fa-solid fa-trash" class="m-0 hover-white" />
          </button>
        </div>
      </FormKit>
      <hr />
      <button type="button" class="btn btn-success" @click.prevent="addItem">
        <fa-icon icon="fa-solid fa-plus" class="me-2" />Ajout ligne
      </button>
      <div class="row mt-3">
        <FormKit
          type="submit"
          :input-class="{
            btn: true,
            'btn-primary': true,
          }"
          ><fa-icon
            icon="fa-solid fa-envelope-open-text famargin"
            class="me-2"
          />Valider</FormKit
        >
      </div>
    </FormKit>
    <div style="margin: 1rem 0" />
  </FormKit>
  <div style="margin: 2rem 0" />
</template>

<style lang="scss">
</style>
