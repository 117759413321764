<template>
  <div class="jp-wrapper">
    <!--<img src="@/assets/images/lennart-butz-idea5anim.gif" class="jp-image" />-->
    <img :src="this.images[this.imgIndex]" class="jp-image" />
    <div class="jp-content text-white text-center">
      <h1>Japon</h1>
      <span>2024</span>
      <jp-button
        class="btn jp-btn m-4"
        target="_blank"
        href="https://lydia-app.com/pots?id=34697-honeymoon-japon-2024"
      >
        <span class="jp-btn-text">Cagnotte</span>
      </jp-button>
      <div class="d-flex flex-row">
        <fa-icon
          icon="fa-solid fa-caret-left"
          class="p-4 pinkhover"
          @click="() => this.update(-1)"
        />
        <fa-icon
          icon="fa-solid fa-caret-right p-4"
          class="p-4 pinkhover"
          @click="() => this.update(1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import JpButton from "@/components/Generic/JpButton.vue";

const images = [
  require("@/assets/images/honeymoon1.gif"),
  require("@/assets/images/honeymoon2.gif"),
  require("@/assets/images/honeymoon6.gif"),
  require("@/assets/images/honeymoon3.gif"),
  require("@/assets/images/honeymoon4.gif"),
  require("@/assets/images/honeymoon7.gif"),
  require("@/assets/images/honeymoon8.gif"),
  require("@/assets/images/honeymoon9.gif"),
  require("@/assets/images/honeymoon10.gif"),
];

export default {
  components: { JpButton },
  methods: {
    update(amount) {
      console.log(this.imgIndex);
      this.imgIndex = (this.imgIndex + amount) % this.images.length;
      if (this.imgIndex < 0) this.imgIndex = this.images.length - 1;
    },
  },
  data() {
    return {
      imgIndex: Math.floor(Math.random() * 3),
      images: images,
    };
  },
};
</script>

<style lang='scss' scoped>


.pinkhover {
  transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  &:hover {
    color: #fab5c9
  }
}
.jp-wrapper {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  text-shadow: 0 0 2px #000;

  & > .jp-image {
    margin-left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    height: 100%;
    display: block;
    height: 20rem;
    z-index: -1;
    height: 100%;
  }

  & > .jp-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .jp-btn {
    border: 2px whitesmoke solid;
    color: whitesmoke;
    display: flex;
    padding: 1rem 2rem;
    font-weight: 700;
    border-radius: 10rem !important;
    overflow: hidden;
    background-color: #ffffff33;

    &:hover {
      border-radius: 10rem;
      color: #a94064;
      text-shadow: none;
    }
  }
}

.navbar {
  background-color: transparent !important;
  & .nav-link {
    color: white !important;
  }
}

html,
body {
  overflow-y: hidden;
  height: 100%;
}
</style>
