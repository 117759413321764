<script setup>
import { onMounted } from "vue";

import Rellax from "rellax";

onMounted(() => {
  let rellax = new Rellax(".rellax");
});
</script>

<script>
import Loading from "vue3-loading-overlay";
import NavBar from "./components/NavBar.vue";
import { Hub } from "aws-amplify";
import AlertManager from "./components/Generic/AlertManager.vue";

export default {
  components: { NavBar, Loading, AlertManager },
  created() {
    console.log(JSON.parse(localStorage.getItem("thumbnailCache")));

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.log("user signed in");
          this.$store.dispatch("logIn", { user: data.payload.data.attributes });
          break;
        case "signUp":
          console.log("user signed up");
          break;
        case "signOut":
          console.log("user signed out");
          this.$store.dispatch("logOut");
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
        case "configured":
          console.log("the Auth module is configured");
      }
      console.log(data);
      console.log(this.$store.state.user);
    });
    this.$store.dispatch("loadUser");
  },
};
</script>

<template>
  <loading
    :active="$store.getters.getLoading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <alert-manager />
  <nav-bar />
  <router-view />
</template>

<style lang='scss'>
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "@/assets/styles/custom.scss";

@import "~bootstrap/scss/bootstrap.scss";
@import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
</style>
