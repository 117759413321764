import {createStore} from 'vuex'
import {Auth} from "aws-amplify"
import {v4 as uuidv4} from "uuid";

export default createStore({
  state: {
    user: undefined,
    loading: false,
    messages: []
  },
  getters: {
    userRetrieved(state) {
      return state.user !== undefined
    },
    getUser(state) {
      return state.user
    },
    getLoading(state) {
      return state.loading
    },
    getMessages(state) {
      return state.messages
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOADING(state, status) {
      state.loading = status
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages
    }
  },
  actions: {
    pushMessage(context, message) {
      var msgs = context.getters.getMessages
      const crafted_message = {
        id: uuidv4(),
        delay: 3000,
        show: true,
        emmitTime: new Date(),
        ...message
      }
      msgs.push(crafted_message)
      if (crafted_message.delay > 0)
        setTimeout(() => context.dispatch('dropMessage', crafted_message.id), crafted_message.delay);
      context.commit("SET_MESSAGES", msgs)
    },
    dropMessage(context, id) {
      var msgs = context.getters.getMessages
      const indexOfObject = msgs.findIndex((item) => {
        return item.id === id;
      });
      if (indexOfObject !== -1) {
        msgs.splice(indexOfObject, 1);
      }
      context.commit("SET_MESSAGES", msgs)
    },
    startLoading(context) {
      context.commit("SET_LOADING", true)
    },
    stopLoading(context) {
      context.commit("SET_LOADING", false)
    },
    logIn(context, user) {
      context.commit("SET_USER", user)
    },
    logOut(context) {
      context.commit("SET_USER", null)
    },
    loadUser(context) {
      context.dispatch('startLoading')
      Auth.currentUserInfo()
        .then((r) => {
          if (r !== null) {
            context.commit("SET_USER", r)
          } else {
            context.commit("SET_USER", null)
          }
        })
        .catch((e) => {
          context.commit("SET_USER", null)
          console.error(e)
        }).finally(() => {
          context.dispatch('stopLoading')
        })
    }
  },
  modules: {
  }
})
