<template>
  <div class="loadbar-container">
    <div
      :id="`loadbar-${id}`"
      class="loadbar"
      :style="{ width: `${percentage}%` }"
    />
  </div>
</template>

<script>
import { v4 } from "uuid";
export default {
  data() {
    return {
      id: v4(),
      interval: null,
      percentage: 100,
    };
  },
  props: {
    refreshRate: {
      type: Number,
      default: 10,
    },
    color: {
      type: String,
      default: "var(--bs-secondary)",
    },
    startTime: Date,
    endTime: Date,
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      const diff = this.endTime.getTime() - this.startTime.getTime();
      const elapsed = Date.now() - this.startTime.getTime();
      this.percentage = 100 - (elapsed / diff) * 100;
    }, this.refreshRate);
  },
};
</script>

<style>
.loadbar {
  height: 5px;
  background-color: v-bind(color);
}

.loadbar-container {
  width: 100%;
}
</style>
