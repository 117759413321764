import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConfirmView from '../views/ConfirmView.vue'
import LoginView from '../views/LoginView.vue'
import ThePlaceView from '../views/ThePlaceView.vue'
import ThemeView from '../views/ThemeView.vue'
import HoneymoonView from '../views/HoneymoonView.vue'
//import PhotosPhotobooth from '../views/Photos-Photobooth.vue'
import PhotosView from '../views/PhotosView.vue'
import PhotosSkler from '../views/Photos/PhotosSkler.vue'
import PhotosVipBox from '../views/Photos/PhotosVipBox.vue'
import PhotosMenu from '../views/Photos/PhotosMenu.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Yes !'
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: ConfirmView,
    meta: {
      requiresLogin: true,
      title: 'Confirmation'
    }
  },
  {
    path: '/place',
    name: 'place',
    component: ThePlaceView,
    meta: {
      title: 'Le lieux'
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: 'Login'
    }
  },
  {
    path: "/theme",
    name: "theme",
    component: ThemeView,
    meta: {
      title: 'Le theme'
    }
  },
    {
    path: "/honeymoon",
    name: "honeymoon",
    component: HoneymoonView,
    meta: {
      title: 'Lune de miel'
    }
  },
  {
    path: '/photos',
    name: 'photos',
    component: PhotosView,
    meta: {
      requiresLogin: true,
      title: 'Photos'
    },
    children: [
      {
        path: '',
        name: 'photos-menu',
        component: PhotosMenu
      },
      {
        path: 'vipbox/:page',
        name: 'vipbox',
        component: PhotosVipBox
      },
      {
        path: 'jskler/:page',
        name: 'jskler',
        component: PhotosSkler
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const title = to.meta.title
    if (title) {
      document.title = title
    }
    if (
      to.matched.some(record => record.meta.requiresLogin) &&
      !store.getters.getUser
    ) {
        next({name: 'login' })
    } else {
      next()
    }
})

export default router
