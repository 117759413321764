/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGuest = /* GraphQL */ `
  query GetGuest($id: ID!) {
    getGuest(id: $id) {
      id
      first_name
      last_name
      food
      allergy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGuests = /* GraphQL */ `
  query ListGuests(
    $id: ID
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGuests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        first_name
        last_name
        food
        allergy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
