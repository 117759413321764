<template>
  <div class="card w-100 my-1">
    <div class="place-image">
      <img :src="this.src" class="w-100" orientation="h" :variant="1" />
    </div>
    <div class="card-body">
      <div class="card-title">
        <slot name="title" />
      </div>
      <div class="card-text">
        <div class="text-muted">
          <ul class="no-bullet-list">
            <li>
              <fa-icon icon="fa-solid fa-users-line" />
              <span class="mx-2">
                {{ this.placeinfo.peoples ?? "?" }}
              </span>
            </li>
            <li>
              <fa-icon icon="fa-solid fa-bed" />
              <span class="mx-2">
                {{ this.placeinfo.bedrooms ?? "?" }}
              </span>
            </li>
            <li>
              <fa-icon icon="fa-solid fa-car" />
              <span class="mx-2">
                {{ this.placeinfo.travel ?? "?" }}
              </span>
            </li>
          </ul>
          <div class="my-2" v-if="this.$slots.extraicons">
            <div class="row">
              <div class="col-12">
                <slot class="my-1" name="extraicons" />
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <slot name="content" />
      </div>
      <slot name="foot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    placeinfo: {
      type: Object,
      default(rawProp) {
        return rawProp.placeinfo ?? {};
      },
    },
  },
};
</script>

<style lang='scss'>
.no-bullet-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  & > li {
    margin-top: .1rem;
  }
}
</style>
