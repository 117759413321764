  <template>
  <div
    class="container"
    ref="masterContainer"
    :style="{ maxHeight: this.fill_height - 50 + 'px' }"
  >
    <div class="row mt-5 mt-md-0">
      <div class="col col-12 col-md-6">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Photos par @Julien SKLER</h5>
            <p class="card-text">
                Les photos officielles à accrocher dans toutes les mairies 📸
            </p>
            <router-link
              :to="{ name: 'jskler', params: { page: 1 } }"
              class="btn btn-primary"
              append
            >
              Voir les photos
            </router-link>
          </div>
          <div class="card-img-bottom menu-img menu-skler">
            <fill-viewport-at-most>
              <s-3-image-thumbnail-loader
                class="w-100"
                s3key="static/Menu-skler.jpg"
                thumbnail_size="720"
              />
            </fill-viewport-at-most>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Photobooth</h5>
            <p class="card-text">
              Vos magnifiques photos prises avec amour ! ❤️
            </p>
            <router-link :to="{ name: 'vipbox', params: { page: 1 } }" class="btn btn-primary">
              Voir les photos
            </router-link>
          </div>
          <div class="card-img-bottom menu-img enu-photobooth">
            <fill-viewport-at-most>
              <s-3-image-thumbnail-loader
                class="w-100"
                thumbnail_size="720"
                s3key="static/Menu-vipbox.jpg"
              />
            </fill-viewport-at-most>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S3ImageThumbnailLoader from "@/components/Images/S3ImageThumbnailLoader.vue";
import FillViewportAtMost from "@/components/Generic/FillViewportAtMost.vue";
export default {
  components: { S3ImageThumbnailLoader, FillViewportAtMost },
  data() {
    return {
      fill_height: 0,
    };
  },
};
</script>

<style scoped lang='scss'>
.menu-selector {
  background-color: red;
  min-height: 200px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-img {
  overflow: hidden;
  top: 0;
  .menu-skler > img {
    width: 100%;
    transform: translate(0, -50px);
  }
}
</style>
