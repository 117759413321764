<template>
  <div
    :class="`mask mask-stain-${this.variant}-${this.orientation} pic-wind ${this.class}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    orientation: {
      type: String,
      default: "v",
    },
    class: String,
    style: String,
    bg: String,
  },
};
</script>

<style>
.mask {
  mask-mode: luminance;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.mask-stain-2-v {
  mask-image: url("@/assets/images/masks/stain-2-v.png");
}

.mask-stain-2-h {
  mask-image: url("@/assets/images/masks/stain-2-h.png");
}

.mask-stain-1-v {
  mask-image: url("@/assets/images/masks/stain-1-v.png");
}


.mask-stain-1-h {
  mask-image: url("@/assets/images/masks/stain-1-h.png");
}

</style>
