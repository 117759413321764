<template >
  <div>
    <paginator-controls
      :page="this.page"
      :route_name="this.route_name"
      :items_count="this.files ? this.files.length : 0"
      :page_size="this.page_size"
    />
    <slot :visible-data="this.page_items" />
    <paginator-controls
      :page="this.page"
      :items_count="this.files ? this.files.length : 0"
      :route_name="this.route_name"
      :page_size="this.page_size"
    />
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import PaginatorControls from "./Paginated/PaginatorControls.vue";
import { useRoute } from "vue-router";

export default {
  components: { PaginatorControls },
  props: {
    loadPrefix: {
      type: String,
      default: "",
    },
    page_size: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      files: null,
    };
  },
  computed: {
    page() {
      return parseInt(this.$route.params.page);
    },
  },
  mounted() {
    this.route_name = useRoute().name
    Storage.list(this.loadPrefix, { pageSize: "ALL", level: "public" }).then(
      (response) => {
        this.files = response.results;
      }
    );
  },
  beforeUpdate() {
    const page_start = Math.max(this.page - 1, 0) * this.page_size;
    const page_end = Math.min(this.page * this.page_size, this.files.length);
    this.page_items = this.files.slice(page_start, page_end);
  },
};
</script>

<style scoped>
</style>
