import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { plugin, defaultConfig } from '@formkit/vue'

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

//<!-- Add all icons to the library so you can use it in your page -->
library.add(fas, far, fab)

//Amplify
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import AmplifyVue from '@aws-amplify/ui-vue';
//import Vuex from 'vuex';
import store from './store/index'

import MasonryWall from '@yeger/vue-masonry-wall'

import mitt from 'mitt';
const emitter = mitt();

Amplify.configure(awsconfig);

const app = createApp(App)

app.use(store)
   .use(router)
   .use(plugin, defaultConfig({
    config:{
      classes:{
        input: "form-control",
        label: "form-label",
        select: "form-select"
      }
    }
  }))
  .use(AmplifyVue)
  .use(MasonryWall)
  .component('fa-icon', FontAwesomeIcon)

app.config.globalProperties.append = (path, pathToAppend) =>
  path + (path.endsWith('/') ? '' : '/') + pathToAppend

app.config.globalProperties.emitter = emitter;
app.mount('#app')
