<template>
  <div>
    <parallax
      :src="require('@/assets/images/ny-hotdog.jpg')"
      :speed="-4"
      position="center"
    >
      <first-section />
    </parallax>
  </div>
  <second-section />
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10527.068702459648!2d1.033399!3d48.7290403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e15fe153440fa3%3A0x9a87ef5e5596b995!2sLe%20Moulin%20Des%20Planches!5e0!3m2!1sfr!2sfr!4v1679075086333!5m2!1sfr!2sfr"
    height="400"
    width="100%"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</template>

<script>
import Parallax from "@/components/Generic/Parallax.vue";
import FirstSection from "@/components/Home/FirstSection.vue";
import SecondSection from "@/components/Home/SecondSection.vue";
export default {
  components: {
    Parallax,
    FirstSection,
    SecondSection,
  },
};
</script>

<style lang="scss">
</style>

