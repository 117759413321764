<template>
  <div v-if="$store.getters.getUser === null">
    <router-link aria-current="page" to="/login">
          <button class="btn btn-outline-primary">Connexion</button>
    </router-link>
  </div>
  <div v-else>
    <Authenticator :hidden='true'/>
    <button class="btn btn-outline-danger" @click="signout">Déconnexion</button>
  </div>
</template>

<script setup>
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
</script>

<script>
export default {
  components:{
    Authenticator
  },
  methods: {
    signout() {
      console.log(
        'SignOut'
      )
      useAuthenticator().signOut()
    }
  }
}
</script>

<style>
</style>
