<template>
  <div class="thumbnail_preloader">
    <img :src="this.thumbnail_link" v-if="this.thumbnail_link" />
    <loader class="loader" v-else />
  </div>
</template>

<script>
import { Storage, Cache } from "aws-amplify";
import Loader from "vue-spinner/src/PulseLoader.vue";

const getImgPropertiesCached = async (key, expires) => {
  const expiry = Math.min(expires, 60);
  const cachedProperties = Cache.getItem(`${key}.properties`);
  if (cachedProperties) {
    return JSON.parse(cachedProperties);
  }
  try {
    const properties = await Storage.getProperties(key);
    Cache.setItem(key, properties, {
      expires: new Date().getTime() + Math.max(0, expiry - 5) * 60000,
    });

    return properties;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getImgLinkCached = async (key, expires) => {
  const expiry = Math.min(expires, 60);
  const cachedImage = Cache.getItem(key);
  if (cachedImage) {
    return cachedImage;
  }
  try {
    const url = await Storage.get(`${key}`, { expires: expiry * 60 });
    Cache.setItem(key, url, {
      expires: new Date().getTime() + Math.max(0, expiry - 5) * 60000,
    });
    return url;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const staticGetThumbnailUrl = async (size, properties, key, expires) => {
  const sz = size.toString();
  const thumbnails = properties.metadata.thumbnails;
  let thumbnail_target = null;

  if (sz == "full") {
    thumbnail_target = key;
  } else {
    if (!(sz in thumbnails))
      throw "Invalid thumbnail size " + sz + ", not in " + thumbnails;
    thumbnail_target = thumbnails[sz].split("/").slice(1).join("/");
  }
  return getImgLinkCached(thumbnail_target, expires);
};

export default {
  components: {
    Loader,
  },
  props: {
    s3key: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      default: "public",
    },
    properties: {
      default: null,
      required: false,
    },
    thumbnail_size: {
      type: String,
      default: "30",
    },
    expires: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      image_url: "",
      thumbnail_link: "",
      properties_data: null,
    };
  },
  methods: {
    async getThumbnail(size) {
      if (this.properties) {
        this.properties_data = this.properties;
      } else {
        const result = await getImgPropertiesCached(this.s3key, this.expires);
        if (result.metadata && "thumbnails" in result.metadata) {
          if ("size" in result.metadata)
            result.metadata.size = result.metadata.size.split("x");
          else result.metadata.size = [1, 1]; //Default

          const thumbnail_obj = JSON.parse(result.metadata.thumbnails);
          result.metadata.thumbnails = thumbnail_obj;
        } else {
          size = "full";
        }
        this.properties_data = result;
      }
      return await staticGetThumbnailUrl(
        size,
        this.properties_data,
        this.s3key,
        this.expires
      );
    },
  },
  mounted() {
    this.getThumbnail(this.thumbnail_size)
      .then((url) => {
        this.thumbnail_link = url;
      })
      .catch((e) => {
        console.error(e);
      });
  },
};
</script>

<style lang='scss'>
.thumbnail_preloader {
  border-radius: 0;
  display: flex;
  background-color: lightgray;
  img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}

.tile-img {
  width: 100%;
  height: 100%;
}

.loader {
  margin: auto;
  > div {
    background-color: gray !important;
  }
}
</style>
