<template>
  <background-imager :src="themeitem.img" class="w-100 theme-item">
    <div class="black-overlay" />
    <div class="text-white overlay-text p-3">
      <h1>{{ themeitem.title }}</h1>
      <table>
        <tr v-for="(value, key) in themeitem.medias" :key="key">
          <td><media-icon :media="key" class="media-icon" /></td>
          <td><span>{{ value }}</span></td>
        </tr>
      </table>
      <p class="my-5">{{ themeitem.content }}</p>
    </div>
  </background-imager>
</template>

<script>
import BackgroundImager from "../Images/BackgroundImager.vue";
import MediaIcon from "./MediaIcon.vue";
export default {
  components: { BackgroundImager, MediaIcon },
  props: {
    themeitem: {
      default: {},
    },
  },
};
</script>

<style lang='scss'>
.theme-item {
  display: block;
  position: relative;
  overflow: hidden;

  &:hover {
    .overlay-text {
      opacity: 1;
    }

    .black-overlay {
      opacity: 0.5;
    }
  }
}

.overlay-text,
.black-overlay {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.black-overlay {
  background-color: black;
}

.media-icon {
  margin: 0 0.3rem;
}
</style>
