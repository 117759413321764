<template>
  <div class="container" style="min-height: 10cm">
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <StainImage class="moulin-des-planches-img" orientation="h" :variant="2">
          <div style="min-height: 10cm" />
        </StainImage>
      </div>
      <div class="col d-flex justify-content-center flex-column m-5 fg-hw">
        <h1 class="fg-hobbit fs-1">Le moulin des planches</h1>
        <div class="w-auto">
          <span class="fs-5">149 Chem. du Moulin des Planches</span>
          <br />
          <span class="fs-5">28270 Montigny-sur-Avre</span>
          <hr />
        </div>
        <p>
          Notre mariage aura lieu dans un magnifique ancien moulin transformé en lieu de réception.
          <br/> Si le temps nous le permets la cérémonie et le cocktail auront lieux dans les jardins du moulin, sinon pas d'inquiétude, nous avons un plan B !
          <br/> A l'arrivée vous allez trouver un parking pour vous garer en toute sécurité, et des éthylotests seront à votre disposition si vous souhaitez rentrer en voiture avant/durant/après la fête
          <br/> Nous sommes impatients de tous vous retrouver le jour de notre mariage !       
        </p>
        <p>
           Et pour que vous puissiez profiter au maximum du moment, voici quelques consignes de sécurité à suivre : 
           <ul> 
            <li> La rivière sur laquelle le moulin est construit n'est sécurisé ni pour les enfants ni pour les adultes alcoolisés.</li>
            <li>S'il vous plait, ne rentrez pas en voiture si vous n'êtes pas en état de conduire.</li>
            <li>Evitez de vous baigner dans la rivière. </li>
          </ul>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1 class="fg-hobbit">Où dormir</h1>
        <p> Vous souhaitez profiter du mariage pour passer le weekend dans l'Eure et Loire ? 
          De nombreux airbnb, gites et hôtels sont à proximité à moins de 15minutes en voiture du Moulin des Planches !
        </p>
        <div class="row">
          <div class="col-sm-12 col-lg-3 col-md-6">
            <place-card
            src="https://a0.muscache.com/im/pictures/miso/Hosting-760759843737636274/original/9b22bf53-13a5-4ecb-bcb7-c00e74c87d0b.jpeg?im_w=1200"
              :placeinfo="{ peoples: 6, bedrooms: 4, travel:'10 min'}"
            >
              <template v-slot:extraicons>
                <hover-icon icon="euro-sign" text="euro" /> <span>135</span>
              </template>
              <template v-slot:title><h5>AirBNB - La grange Chèvrefeuille</h5> </template>
              <template v-slot:content>
                <p>
                  A 10 minutes en voiture du Moulin des planches, le logement entier offre 2 chambres, 4 lits  et peut accueillir jusqu'à 6 voyageurs.                </p>
              </template>
              <template v-slot:foot>
                <a class="btn btn-primary" href="https://www.airbnb.fr/rooms/774719525825611795?check_in=2023-10-14&check_out=2023-10-15&guests=1&adults=5&s=67&unique_share_id=00ccd7e2-d2ab-4eef-a7e1-59ffc6805fa1">Plus d'informations</a>
              </template>
            </place-card>
          </div>
          <div class="col-sm-12 col-lg-3 col-md-6">
            <place-card
              src="https://a0.muscache.com/im/pictures/miso/Hosting-760759843737636274/original/d92f7ea4-867c-49e7-aa13-3fef1a0ac510.jpeg?im_w=1440"
              :placeinfo="{ peoples: 7, bedrooms: 5, travel: '10 min'}"
              >
              <template v-slot:extraicons>
                <hover-icon icon="euro-sign" text="euro" /> <span>130</span>
              </template>
              <template v-slot:title><h5>Airbnb - La grange Mirabelle </h5> </template>
              <template v-slot:content
                >
              <p>
                Ce 2ème logement se situe sur le même terrain que la Grange du bois Normand et peut accueillir 7 personnes dans 3 chambres.
                </p>
              </template>
              <template v-slot:foot>
                <a class="btn btn-primary" href="https://www.airbnb.fr/rooms/774694580053224087?check_in=2023-10-14&check_out=2023-10-15&guests=7&adults=7&s=67&unique_share_id=85c74dd6-eb86-4efa-825c-4d0efeea77d4">Plus d'informations</a>
              </template>
            </place-card>
          </div>
          <div class="col-sm-12 col-lg-3 col-md-6">
            <place-card
              src="https://a0.muscache.com/im/pictures/01f6ecef-1f68-416c-9f2e-2ddab7608ecd.jpg?im_w=1200"
              :placeinfo="{ peoples: '4 (6)', bedrooms: 2, travel: '8 min' }"
            >
            <template v-slot:extraicons>
                <hover-icon icon="euro-sign" text="euro" /> <span>127</span>
              </template>
              <template v-slot:title><h5>Logement à Bälines</h5> </template>
              <template v-slot:content
                ><p>
                  Logement à 8 minutes du Moulin des Planches, 2 chambres disponibles pour 4 personnes avec possibilité de rajouter un lit d'appoint dans chaque chambre pour un total de 6 personnes.                </p>
              </template>
              <template v-slot:foot>
                <a class="btn btn-primary" href="https://www.airbnb.fr/rooms/24514959?check_in=2023-10-14&check_out=2023-10-15&guests=1&adults=4&s=67&unique_share_id=01593517-aed2-4ef4-81bd-dd83fb83fac6">Plus d'informations</a>
              </template>
            </place-card>
          </div>
          <div class="col-sm-12 col-lg-3 col-md-6">
            <place-card
              src="http://www.hoteldusaumon.fr/maj/images_resized/674-so-2014-accueil-photo-anim01-fr.jpg"
              :placeinfo="{ peoples: '2 à 4', bedrooms: 2, travel: '13 min' }"
            >
            <template v-slot:extraicons>
                <hover-icon icon="euro-sign" text="euro" /> <span>50 à 90</span>
              </template>
              <template v-slot:title><h5>Hotel du saumon</h5> </template>
              <template v-slot:content
                ><p>
                  L'hôtel du saumon se situe à Verneuil sur Avre à 13 minutes en voiture du moulin des planches.
                  Il offre plusieurs chambres de 2 à 4 personnes par chambre. Vous pouvez les contacter directement par téléphone pour réserver vos chambres à un prix intéressant.
               </p>
              </template>
              <template v-slot:foot>
                <a class="btn btn-primary" href="https://www.airbnb.fr/rooms/24514959?check_in=2023-10-14&check_out=2023-10-15&guests=1&adults=4&s=67&unique_share_id=01593517-aed2-4ef4-81bd-dd83fb83fac6">Plus d'informations</a>
              </template>
            </place-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StainImage from "@/components/Images/StainImage.vue";
import PlaceCard from "@/components/Generic/PlaceCard.vue";
import HoverIcon from "@/components/Generic/HoverIcon.vue";

export default {
  components: {
    StainImage,
    PlaceCard,
    HoverIcon,
  },
};
</script>

<style>
.h-15rem {
  height: 15rem;
}

.moulin-des-planches-img {
  background-image: url("@/assets/images/moulin-des-planches.jpeg");
  background-size: cover;
  background-position: center;
}
</style>
