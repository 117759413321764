<template>
  <div class="scroll-container">
    <UseElementSize v-slot="{ width }">
      <MasonryWall
        ref="masonry"
        :items="files"
        :column-width="this.column_width"
        :gap="this.gaps"
        :min-columns="1"
        :rtl="true"
        class="noselect"
      >
        <template v-slot:default="{ item }">
          <div
            class="image-wrapper"
            :style="{
              height:
                this.scaleHeight(item.properties.metadata.size, width) + 'px',
              width: width,
            }"
          >
            <download-button :path="item.s3obj.key" class="download-button">
              <fa-icon icon="fa-icon fa-download" />
            </download-button>
            <s-3-image-thumbnail-loader
              class="tile-img rounded"
              :s3key="item.s3obj.key"
              thumbnail_size="720"
              :properties="item.properties"
            />
          </div>
        </template>
      </MasonryWall>
    </UseElementSize>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import { UseElementSize } from "@vueuse/components";
import S3ImageThumbnailLoader from "./S3ImageThumbnailLoader.vue";
import DownloadButton from "@/components/Forms/DownloadButton.vue";

export default {
  components: {
    DownloadButton,
    UseElementSize,
    S3ImageThumbnailLoader,
  },
  props: {
    column_width: {
      type: Number,
      default: 300,
    },
    gaps: {
      type: Number,
      default: 5,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      next_token: undefined,
      has_next_page: true,
      mounted: false,
      progress: {},
    };
  },
  methods: {
    scaleHeight(size, referenceWidth) {
      const colCount = ~~(referenceWidth / this.column_width);
      const effective_col_width =
        (referenceWidth - this.gaps * (colCount + 1)) / colCount;
      const width = size[0];
      const height = size[1];
      const scaled_height =
        (height / width) * effective_col_width + this.gaps / 2;
      return scaled_height;
    },
    getFilesPage() {
      if (!this.items) return;
      let promises = [];
      let local_file_list = [];
      for (let i = 0; i < this.items.length; i++) {
        const s3object = this.items[i];
        if (s3object.size == 0) {
          continue;
        }

        promises[i] = Storage.getProperties(s3object.key, {
          cacheControl: "no-cache",
        }).then((result) => {
          if (result.metadata && "thumbnails" in result.metadata) {
            if ("size" in result.metadata)
              result.metadata.size = result.metadata.size.split("x");
            else result.metadata.size = [1, 1]; //Default

            const thumbnail_obj = JSON.parse(result.metadata.thumbnails);
            result.metadata.thumbnails = thumbnail_obj;

            const myobj = { s3obj: s3object, properties: result };
            local_file_list.push(myobj);
          }
        });
      }
      Promise.allSettled(promises)
        .then(async () => {
          await this.$nextTick();
          this.files = local_file_list;
        })
        .finally(() => {
          this.$store.dispatch("stopLoading");
        });
    },
  },
  beforeUpdate() {
    this.$store.dispatch("startLoading");
    this.getFilesPage();
  },
};
</script>

<style lang='scss'>
@import "@/assets/styles/theme.scss";

.download-all {
  margin-bottom: 1rem;
}

.image-wrapper {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  & > .tile-img {
    width: 100%;
    height: 100%;
  }
}

.download-button {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 200ms;
  display: flex;
  z-index: 1;
  border-radius: 0;
  &:hover {
    opacity: 1;
    background-color: transparentize(#000000, 0.5);
  }

  & svg {
    margin: auto;
    color: #ffffff;
    width: 4rem;
    height: 4rem;
  }
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.scroll-container {
  overflow-y: auto;
}
</style>
