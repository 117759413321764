<template>
  <div class="toast-container p-5">
    <div
      v-for="item in getMessages"
      :key="item.id"
      :id="item.id"
      class="toast"
      :class="{
        show: item.show,
      }"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <loading-bar
        v-if="item.delay > 0"
        :endTime="new Date(item.emmitTime.getTime() + item.delay)"
        :startTime="item.emmitTime"
        class="alert-loadbar-container"
        :color="item.color"
      />
      <div class="toast-header">
        <img v-if="item.src" :src="item.src" class="rounded me-2 toast-img" />
        <strong class="me-auto">{{ item.title ?? "undefined" }}</strong>
        <button
          type="button"
          @click="removeItem(item.id)"
          class="btn-close"
        ></button>
      </div>
      <div class="toast-body">
        <span v-for="(line, index) in item.lines" :key="index">
          {{ line }}
          <br />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingBar from "./LoadingBar.vue";

export default {
  components: { LoadingBar },
  computed: {
    ...mapGetters({
      getMessages: "getMessages",
    }),
  },
  methods: {
    removeItem(itemId) {
      this.$store.dispatch("dropMessage", itemId);
    },
  },
};
</script>

<style lang='scss'>
.alert-loadbar-container {
  position: relative;
  top: 0;
  left: 0;
}

.toast {
  overflow: hidden;
}

.toast-img {
  max-width: 3rem;
  max-height: 3rem;
}
</style>
