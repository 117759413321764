<template>
  <a
    class="fill-btn"
    target="_blank"
  >
    <slot class="jp-btn-text" />
  </a>
</template>

<script>
export default {
  props: {
    hoverColor: { default: "var(--bs-primary)" },
  },
}
</script>

<style lang='scss'>
.fill-btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;

  &:hover {
    border-color: v-bind(hoverColor) !important;
  }

  &:before {
    content: "";
    position: absolute;
    background: v-bind(hoverColor) !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
  }

  &:hover:before {
    top: 0;
  }

  & > .jp-btn-text {
    margin: auto;
  }
}
</style>
