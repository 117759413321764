<template>
  <div class="container">
    <div class="row">
      <span class="my-3 fs-2 fg-hw "
        >Parez votre tenue de soirée d'un accessoire rappelant votre oeuvre de fantasy préféré. 
        </span
      >
      <span class="my-3 fs-5"
        >Il existe des milliers d'unviers fantastiques qu'on a tous forcément découvert à un moment de notre vie, voici certains exemples:</span
      >
    </div>
    <div class="row">
      <masonry-wall :items="shuffledArray" :column-width="300" :gap="10">
        <template v-slot:default="{ item }">
          <theme-tile
            :themeitem="item"
            class="min-h masonry-item"
            :style="{ height: `${item.height}rem` ?? 0 }"
          />
        </template>
      </masonry-wall>
    </div>
  </div>
</template>

<script>
import ThemeTile from "@/components/Theme/ThemeTile.vue";
export default {
  components: { ThemeTile },
  computed: {
    shuffledArray() {
      var array = [...this.items];
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
  },
  data() {
    return {
      items: [
        {
          title: "Le seigneur des anneaux",
          content: "Lol",
          img: "https://m.media-amazon.com/images/M/MV5BN2EyZjM3NzUtNWUzMi00MTgxLWI0NTctMzY4M2VlOTdjZWRiXkEyXkFqcGdeQXVyNDUzOTQ5MjY@._V1_.jpg",
          height: 40,
          medias: {
            movie: "Peter Jackson",
            book: "J.R.R Tolkien",
          },
        },
        {
          title: "La saga du sorceleur",
          content: "dadsa",
          img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRrq6cRK12zrAuOlcUjC7auIPLMdvUJSlS0IzoYkaGF5mOYFim1",
          height: 45,
          medias: {
            serial: "Lauren Schmidt Hissrich (Netflix)",
            book: "Andrzej Sapkowski",
            videogame: "CD Project Red",
          },
        },
        {
          title: "Le throne de fer",
          content: "A song of ice and fire",
          img: "https://cache.cosmopolitan.fr/data/photo/w1000_ci/4x/ned-stark.jpg",
          height: 20,
          medias: {
            book: "G.R.R Martin",
            serial: "HBO",
          },
        },
        {
          title: "Bilbo le hobbit",
          content: "Je pars pour une aventure",
          img: "https://resize.elle.fr/portrait/var/plain_site/storage/images/loisirs/cinema/news/j-y-vais-j-y-vais-pas/le-hobbit-un-voyage-inattendu-retour-a-tatons-sur-la-terre-du-milieu-2265962/26377992-1-fre-FR/Le-Hobbit-un-voyage-inattendu-retour-a-tatons-sur-la-Terre-du-Milieu.jpg",
          height: 30,
          medias: {
            book: "J.R.R Tolkien",
            serial: "Peter Jackson",
          },
        },
        {
          title: "Avatar le dernier maitre de l'air",
          content: "",
          img: "https://fr.web.img3.acsta.net/c_310_420/medias/nmedia/18/69/15/09/18864429.jpg",
          height: 15,
          medias: {
            serial: "Nickelodeon",
          },
        },
        {
          title: "La quete d'Ewilan",
          content: "",
          img: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/81BUxbsygnL.jpg",
          height: 30,
          medias: {
            book: "Pierre Bottero",
          },
        },
        {
          title: "Les annales du Disque-Monde",
          content: "",
          img: "https://www.dol-celeb.com/wp-content/uploads/2015/04/discworld.jpg",
          height: 20,
          medias: {
            book: "Terry Pratchett",
          },
        },
        {
          title: "L'histoire sans fin",
          content: "",
          img: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/51++RSjiuHL._AC_SY445_.jpg",
          height: 40,
          medias: {
            movie: "Wolfgang Petersen",
            book: 'Michael Ende'
          },
        },
        {
          title: "Le pacte des marchombres",
          content: "",
          img: "https://www.babelio.com/couv/CVT_25168_1169343.jpg",
          height: 30,
          medias: {
            book: "Pierre Bottero",
          },
        },
        {
          title: "Eragon",
          content: "",
          img: "https://upload.wikimedia.org/wikipedia/commons/8/83/Christopher_Paolini%2C_Eragon_1.jpg",
          height: 20,
          medias: {
            book: "Christopher Paolini",
            movie: "Stefen Fangmeier",
          },
        },
        {
          title: "Conan le Barbare",
          content: "",
          img: "https://i0.wp.com/www.filmspourenfants.net/wp-content/uploads/2018/12/conan-le-barbare-a.jpg?fit=333%2C500&ssl=1",
          height: 30,
          medias: {
            book: "Robert Ervin Howard",
            movie: "John Milius",
          },
        },
        {
          title: "Le donjon de Naheulbeuk",
          content: "",
          img: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/71mxTvuniqL.jpg",
          height: 30,
          medias: {
            audio: "John Lang (PenOfChaos)",
            book: "John Lang (PenOfChaos)",
          },
        },
        {
          title: "L'assassin Royal",
          content: "",
          img: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/516Sr57+HwL.jpg",
          height: 20,
          medias: {
            book: "Robin Hobb",
          },
        },
        {
          title: "Harry Potter",
          content: "",
          img: "https://static.posters.cz/image/1300/affiches-et-posters/harry-potter-a-l-ecole-des-sorciers-i104639.jpg",
          height: 30,
          medias: {
            book: "J.K Rowling",
            movie: "Warner Bros Studio",
          },
        },
        {
          title: "The Elder Scrolls V : Skyrim",
          content: "",
          img: "https://static.actugaming.net/media/2016/03/the-elder-scrolls-skyrim-game-cover.jpg",
          height: 20,
          medias: {
            videogame: "Beteshda",
          },
        },
        {
          title: "Dofus",
          content: "",
          img: "https://frenchgeekmovement.fr/wp-content/uploads/2014/04/dofus.jpg",
          height: 40,
          medias: {
            videogame: "Ankama Games",
            movie: "Ankama Studio",
          },
        },
        {
          title: "Dragon Age",
          content: "",
          img: "https://image.jeuxvideo.com/images/jaquettes/00012937/jaquette-dragon-age-origins-pc-cover-avant-g.jpg",
          height: 20,
          medias: {
            videogame: "Bioware",
          },
        },
        {
          title: "Wakfu",
          content: "",
          img: "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/912wsUhzLgL.jpg",
          height: 30,
          medias: {
            videogame: "Ankama Games",
            serial: "Ankama Studio",
          },
        },
        {
          title: "World of Warcraft",
          content: "",
          img: "https://image.jeuxvideo.com/images/pc/w/o/wowapc0f.jpg",
          height: 20,
          medias: {
            videogame: "Blizzard Entertainment",
          },
        },
        {
          title: "Noob",
          content: "",
          img: "https://fr.web.img2.acsta.net/medias/nmedia/18/68/93/59/19188956.jpg",
          height: 20,
          medias: {
            serial: "Olidry studio",
          },
        },
        {
          title: "Genshin Impact",
          content: "",
          img: "https://cdn1.epicgames.com/offer/879b0d8776ab46a59a129983ba78f0ce/genshintall_1200x1600-4a5697be3925e8cb1f59725a9830cafc",
          height: 25,
          medias: {
            videogame: "MiHoYo",
          },
        },
        {
          title: "Monster Hunter",
          content: "",
          img: "https://www.playtest.fr/wp-content/uploads/2020/11/20180810142826_scdk.jpg",
          height: 30,
          medias: {
            videogame: "Capcom",
          },
        },
        {
          title: "Hollow Knight",
          content: "",
          img: "https://s3.gaming-cdn.com/images/products/2198/orig/hollow-knight-pc-mac-jeu-steam-cover.jpg?v=1649252899",
          height: 10,
          medias: {
            videogame: "TeamCherry",
          },
        },
        {
          title: "La légende de Korra",
          content: "",
          img: "https://www.france.tv/image/vignette_16x9/800/450/g/e/n/20de2bd6-phpoz4neg_png.jpg",
          height: 10,
          medias: {
            serial: 'Nickelodeon'
          },
        },
        {
          title: "Pillars of Eternity",
          content: "",
          img: "https://upload.wikimedia.org/wikipedia/fr/thumb/2/29/Pillars_of_Eternity_Logo.jpg/1200px-Pillars_of_Eternity_Logo.jpg",
          height: 10,
          medias: {
            videogame: 'Obsidian Studio'
          },
        },
        {
          title: "Les maitres de l'univers",
          content: "",
          img: "https://www.ttu.fr/wp-content/uploads/2022/03/musclor.jpg",
          height: 20,
          medias: {
            serial: 'Roger Sweet (Mattel)'
          },
        },
        {
          title: "Le monde de Narnia",
          content: "",
          img: "https://fr.web.img2.acsta.net/medias/nmedia/18/35/53/32/18463695.jpg",
          height: 20,
          medias: {
            book: 'C.S. Lewis',
            movie: ''
          },
        },
        {
          title: "Star Trek",
          content: "",
          img: "https://fr.web.img6.acsta.net/c_310_420/medias/nmedia/18/83/14/16/19667576.jpg",
          height: 20,
          medias: {},
        },
        {
          title: "Albator",
          content: "",
          img: "https://www.maxoe.com/img/uploads/2018/02/Albator-haut-1.jpg",
          height: 15,
          medias: {
            book: 'Leiji Matsumoto',
            serial: 'Chiaki Imada'
          },
        },
        {
          title: "Capitaine Flam",
          content: "",
          img: "https://www.filmspourenfants.net/wp-content/uploads/2018/02/capitaine-flam-a-390x500.jpg",
          height: 15,
          medias: {
            serial: 'Tomoharu Katsumata (Tōei animation)'
          },
        },
      ],
    };
  },
};
</script>

<style>
.min-h {
  /*min-height: 20rem;*/
  width: 100%;
}

.masonry-item {
  /*margin: 5px 10px;*/
  border-radius: 0.2rem;
}
</style>
