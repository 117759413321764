<template>
  <div class="container">
    <confirmation-form />
  </div>
</template>

<script>
import ConfirmationForm from "@/components/Forms/ConfirmationForm.vue";
export default {
  components: { ConfirmationForm },
};
</script>
