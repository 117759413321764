<template>
  <div class="image">
    <slot>
      <div class="placeholder" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return `url(${this.src})`;
    },
  }
};
</script>

<style lang='scss' scoped>
.placeholder {
  min-height: 5cm;
  min-width: 5cm;
}

.image {
  background-image: v-bind(backgroundImage);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: fit-content;
  height: fit-content;
}
</style>
