<template>
  <background-imager :src="this.src" :class="`mask ${maskClass} ${this.class}`">
    <slot>
    </slot>
  </background-imager>
</template>

<script>
import BackgroundImager from "./BackgroundImager.vue";
export default {
  components: { BackgroundImager },
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    orientation: {
      type: String,
      default: "v",
    },
    class: String,
    src: String,
  },
  data() {
    return {
      image: "",
    };
  },
  computed: {
    maskClass() {
      return `mask-stain-${this.variant}-${this.orientation}`
    }
  }
};
</script>

<style lang='scss' scoped>
.mask {
  mask-mode: alpha;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  /*width: 100%;*/
  /*height: 100%;*/
}

$orientations: v, h;
.mask {
  background-position: center;
  background-size: cover;
}

.mask-stain {
  @for $variant from 1 through 2 {
    @each $orientation in $orientations {
      &-#{variant}-#{orientation} {
        mask-image: url("@/assets/images/masks/stain-#{$variant}-#{$orientation}.png");
        -webkit-mask-image: url("@/assets/images/masks/stain-#{$variant}-#{$orientation}.png");
      }
    }
  }
};
</style>
