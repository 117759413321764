<template>
  <div :class="`parallax-wrapper ${outerclass}`">
    <div
      :class="`rellax parallax-img ${innerclass}`"
      :data-rellax-speed="speed"
      :style="`transform: translateY(-25%); min-height: 150%; background-image: url('${src}');background-position: ${position}`"
    />
    <slot />
  </div>
</template>

<script>
/* eslint vue/multi-word-component-names: off */  // --> OFF
export default {

  props: {
    src: String,
    speed: Number,
    position: {
      type: String,
      default: "center"
    },
    innerclass: {
      type: String,
      default: ""
    },
    outerclass: {
      type: String,
      default: ""
    },
  }
};
</script>

<style scoped lang="scss">
.parallax-wrapper {
  overflow: hidden;
  position: relative;
}

.parallax-img {
  z-index: -1000;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 200%;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>

<script setup>
import { onMounted } from "vue";

import Rellax from "rellax";

onMounted(() => {
  let rellax = new Rellax(".rellax");
});
</script>
