<template>
  <div class="paginator-ctrl" v-if="this.items_count">
    <div>
      <ul class="paginator-ctrl-page-selector-list link-dark">
        <li class="paginator-ctrl-page-iterator" v-if='this.page > this.min_page'>
          <router-link
            :to="{
              name: this.route_name,
              params: {
                page: this.page - 1,
              },
            }"
          >
            <fa-icon icon="caret-left" />
          </router-link>
        </li>
        <div v-if="this.pages_list[0] > this.min_page">
          <li class="paginator-ctrl-page-selector">
            <router-link
              :to="{
                name: this.route_name,
                params: {
                  page: this.min_page,
                },
              }"
            >
              {{ this.min_page }}
            </router-link>
          </li>
          <li
            class="paginator-ctrl-page-selector-three-dots text-muted"
            v-if="this.pages_list[0] > this.min_page + 1"
          >
            ...
          </li>
        </div>
        <li
          class="paginator-ctrl-page-selector"
          v-for="i in this.pages_list"
          :key="i"
        >
          <router-link
            :class="{ 'lnk-active': this.page == i }"
            :to="{
              name: this.route_name,
              params: {
                page: i,
              },
            }"
          >
            {{ i }}
          </router-link>
        </li>
        <div v-if="this.pages_list[this.pages_list.length - 1] < this.maxPage">
          <li
            class="paginator-ctrl-page-selector-three-dots"
            v-if="
              this.pages_list[this.pages_list.length - 1] < this.maxPage - 1
            "
          >
            ...
          </li>
          <li class="paginator-ctrl-page-selector">
            <router-link
              :to="{
                name: this.route_name,
                params: {
                  page: this.maxPage,
                },
              }"
            >
              {{ this.maxPage }}
            </router-link>
          </li>
        </div>
        <li class="paginator-ctrl-page-iterator" v-if="this.page < this.maxPage">
          <router-link
            :to="{
              name: this.route_name,
              params: {
                page: this.page + 1,
              },
            }"
          >
            <fa-icon icon="caret-right" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route_name: {
      type: String,
      default: null,
    },
    items_count: {
      type: Number,
      require: true,
    },
    page_size: {
      type: Number,
    },
    page: {
      type: Number,
      require: true,
    },
    display_pages_distance: {
      type: Number,
      default: 2,
    },
    min_page: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    pages_list() {
      let ret = [];
      let from = Math.max(
        this.min_page,
        Math.min(
          this.maxPage - this.display_pages_distance * 2,
          this.page - this.display_pages_distance
        )
      );
      let to = Math.min(
        this.maxPage,
        Math.max(
          this.min_page + this.display_pages_distance * 2,
          this.page + this.display_pages_distance
        )
      );
      for (let i = from; i <= to; i++) ret.push(i);
      return ret;
    },
    maxPage() {
      return ~~(this.items_count / this.page_size) + 1;
    },
  },
};
</script>

<style lang='scss' scoped>
.link_disabled {
  pointer-events: none;
  color: lightgray;
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
  padding: 0.5rem;
}

ul.paginator-ctrl-page-selector-list {
  li {
    margin: 0 0.2rem;
  }
  list-style: none;
  margin: 2rem 0;
  padding: 0;
  color: var(--bs-dark-rgb);
  display: inline-flex;
  align-items: center;

  & > div {
    display: inline-flex;
  }
}
.paginator-ctrl-page-iterator {
  > a {
    padding: 0 1rem;
  }
  color: var(--bs-primary);
  font-size: 2rem;
}

.paginator-ctrl-page-selector {
  > a {
    border-radius: 5px;
    background-color: transparentize($color: lightgray, $amount: 0.75);
  }
  > a.lnk-active {
    background-color: var(--bs-primary);
    color: white;
    font-weight: 1000;
  }
}

.paginator-ctrl-page-selector-three-dots {
  color: gray;
  opacity: 0.5;
}
</style>
