<template>
  <div class="p-y-5">
    <div class="container">
      <div class="my-4 row d-flex flex-row-reverse">
        <div class="col-sm-12 col-lg-4 d-flex justify-content-center">
          <StainImageV2
            :src="require('@/assets/images/thibaut.jpeg')"
            :style="{'width': '20rem'}"
          >
            <div style="min-width: 300px; min-height: 25rem" />
          </StainImageV2>
        </div>
        <div
          class="my-5 col-sm-12 col-md-12 col-lg-4 d-flex flex-column align-items-center justify-content-center"
        >
          <h1 class="fg-hobbit">14 Octobre 2023</h1>
          <h2 class="fg-hobbit">14h30</h2>
          <h3 class="fg-hw">Le moulin des planches</h3>
          <router-link to="/honeymoon">
          <jp-button class="jp-button btn pink m-2">
              Voyage de noces
            </jp-button>
          </router-link>
        </div>
        <div class="col-sm-12 col-lg-4 d-flex justify-content-center">
          <StainImageV2 :src="require('@/assets/images/ana.jpeg')">
            <div style="min-width: 300px; min-height: 25rem" />
          </StainImageV2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StainImageV2 from "../Images/StainImageV2.vue";
import JpButton from "../Generic/JpButton.vue";

export default {
  components: {
    StainImageV2,
    JpButton,
  },
};
</script>

<style lang="scss">
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jp-button {
  padding: 0.7rem 1.5rem;
  border: 2px none solid;
  border-radius: 10rem;
  font-weight: 700;

  &.pink {
    color: #fab5c9;
    border-color: #fab5c9;
    &:hover {
      color: #a94064;
    }
  }

  &.darkpink {
    color: #a94064;
    border-color: #a94064;
    &:hover {
      color: #fff;
    }
  }
}
</style>
