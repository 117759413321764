<template>
  <div class="container-fluid position-relative my-1 photo-div">
    <download-button
      class="download-all btn btn-primary"
      path="static/zips/vipbox/"
      :show_size='true'
    />
    <s-3-mansonry-paginated loadPrefix="vipbox/" />
  </div>
</template>

<script>
import DownloadButton from "@/components/Forms/DownloadButton.vue";
import S3MansonryPaginated from "@/components/Images/S3MansonryPaginated.vue";

export default {
  components: {
    DownloadButton,
    S3MansonryPaginated,
  },
};
</script>

<style lang='scss'>
.thanks-skler {
  img {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    margin: 1rem;
  }
}
</style>
