<template>
  <div class="container" style="min-height: 20rem">
    <div class="row">
      <div class="relative col-sm-12 col-lg-4">
        <div class="text-emphasis absolute center"></div>
        <div class="m-5 fg-white">
          <h1 class="fw-bold fs-1 fg-hobbit">Ana et Thibaut</h1>
          <p class="fs-5 fg-hw">
            Après deux ans d'amitié, c'est dans l'Illinois que notre histoire d'amour a pris racine.
            5 ans de relation, 5 ans durant lequels nous avons partagé d'innombrables premières fois...
            Notre premier appartement, notre premier job, notre premier chat, notre première maison, notre premier chien...
            Enfin, nous allons franchir une étape de plus: LE mariage! 
            Si vous lisez ceci,c'est que vous comptez énormément pour nous et nous aimerions vous avoir à nos cotés en ce jour si spécial.            
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.absolute.center {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.text-emphasis {
  height: 150%;
  width: 250%;
  left: -25% !important;
  z-index: -5;
  background: radial-gradient(
    ellipse closest-side,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
