<template>
  <nav
    :class="[
      'navbar',
      'navbar-expand-lg',
      'navbar-light',
      altBackground ? 'navbar-bg-transparent' : '',
    ]"
  >
    <div class="container">
      <a class="navbar-brand" href="#"> </a>
      <button
        class="navbar-toggler btn btn-outline-light"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon">
          <fa-icon
            icon="fa-solid fa-bars"
            style="color: #000; font-size: 28px"
          />
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/"
              >Accueil</router-link
            >
          </li>
          <!-- 
          <li class="nav-item">
            <router-link
              class="nav-link active"
              aria-current="page"
              to="/confirm"
              >Votre réponse?</router-link
            >
          </li>
          -->
          <!-- 
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/place"
              >Lieu & Hébergement</router-link
            >
          </li>
          -->
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/photos"
              >Photos</router-link
            >
          </li><li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/theme"
              >Le Theme</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link active"
              aria-current="page"
              to="/honeymoon"
              >Voyage de noces</router-link
            >
          </li>
        </ul>
        <login-btn class="navbar-text" />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import LoginBtn from "./NavBar/LoginBtn.vue";

export default {
  computed: {
    ...mapState(["user"]),
    altBackground() {
      const path = this.$route.path.split("/");
      return path[path.length - 1].toLowerCase() === "honeymoon";
    },
  },
  components: {
    LoginBtn,
  },
};
</script>

<style lang='scss'>
@import "~bootstrap/scss/bootstrap.scss";

.nav-item {
  margin: auto 0.3rem;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
  /*--bs-navbar-active-color: #a94064;*/
  &:hover > a {
    color: var(--bs-navbar-hover-color) !important;
  }
}

.navbar {
  background: white;
  z-index: 1;
  --bs-navbar-active-color: #a94064 !important;
  --bs-navbar-hover-color: #fab5c9 !important;
  @include media-breakpoint-down(lg) {
    padding: 0 !important;
  }
}

.navbar-brand {
  padding: 0 !important;
  margin: 0;
}

.navbar-toggler {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
  background-color: #ffffff55 !important;
  border: 2px #fff solid !important;
}

.navbar-collapse {
  @include media-breakpoint-up(lg) {
    margin: 0.3rem;
  }
  margin: 1rem 0;
}

.navbar.navbar-bg-transparent {
  @include media-breakpoint-up(lg) {
    --bs-navbar-color: white;
    --bs-navbar-active-color: white !important;
    --bs-navbar-hover-color: #fab5c9 !important;
    text-shadow: 0 0 2px #000;
    background-color: transparent;
  }
}
</style>
