<template>
  <div ref='masterDiv' class="master-div" :style='{maxHeight: `${maxHeight}px`}'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maxHeight: 0
    }
  },
  methods: {
    computeMaxHeight() {
      this.maxHeight = document.documentElement.clientHeight - this.$refs.masterDiv.getBoundingClientRect().top - 30
    }
  },
  mounted() {
    window.addEventListener("resize", this.computeMaxHeight);
    this.computeMaxHeight();
  },
  unmounted() {
    window.removeEventListener("resize", this.computeMaxHeight);
  },
}
</script>

<style lang='scss' scoped>
.master-div {
  overflow: hidden;
}
</style>
