<template>
  <div>
    <VuePaginated
      :loadPrefix="this.loadPrefix"
      :page_size="this.page_size"
    >
      <template #default="{ visibleData }">
        <S3ImageMasonry :items="visibleData" />
      </template>
    </VuePaginated>
  </div>
</template>

<script>
import S3ImageMasonry from "./S3ImageMasonry.vue";
import VuePaginated from "../Generic/VuePaginated.vue";
export default {
  props: {
    loadPrefix: {
      type: String,
      default: "",
    },
    page_size: {
      type: Number,
      default: 50,
    },
  },
  components: { S3ImageMasonry, VuePaginated },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
