<template>
    <fa-icon :icon="`fa-solid fa-${iconName}`" />
</template>

<script>
const icon_conversion_table = {
  'book': 'book',
  'movie': 'film',
  'serial': 'file-video',
  'videogame': 'gamepad',
  'audio': 'volume-high'
}

export default {
  props: {
    media: String
  },
  computed: {
    iconName() {
      if (this.media in icon_conversion_table)
        return icon_conversion_table[this.media]
      return 'question'
    }
  }
}
</script>

<style>
</style>
