<template>
  <fa-icon :icon="`fa-solid fa-${this.icon}`" :title="this.text"/>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
